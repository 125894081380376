import React, { useState } from 'react';
import './App.css'; // Import your CSS file for styling
import modiImage from './modi.jpg';
import rahulImage from './rahul.jpeg';
import logo from './truevote-logo.jpeg';
import {
  TwitterShareButton,
  XIcon,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon
} from 'react-share';
import ReactGA from 'react-ga';

function App() {
  ReactGA.initialize('G-TEYP3KLRTF');
  const [incomeChangeBJP, setIncomeChangeBJP] = useState('\u00B10%');
  const [taxChangeBJP, setTaxChangeBJP] = useState('\u00B10%');
  const [incomeChangeCongress, setIncomeChangeCongress] = useState('\u00B10%');
  const [taxChangeCongress, setTaxChangeCongress] = useState('\u00B10%');
  const [errorMessage, setErrorMessage] = useState('');

  const shareUrl = 'https://www.truevote.in';
  const title = 'Discover in under 10 seconds who is better for you financially, BJP or Congress, in this Lok Sabha 2024 election with TrueVote! 🇮🇳';
  const titleWithTags = 'Discover in under 10 seconds who is better for you financially, BJP or Congress, in this Lok Sabha 2024 election with TrueVote!\n#TrueVote #LokSabhaElection2024 🇮🇳';
  const calculateButton = () => {
    // Get values from input fields
    const selectedMaritalStatus = document.querySelector('input[name="marital_status"]:checked');
    const selectedAge = document.getElementById('age');
    const selectedGender = document.querySelector('input[name="gender"]:checked');
    const selectedIncomeRange = document.getElementById('income_range');
    const selectedDependents = document.querySelector('input[name="dependents"]:checked');

    if (!selectedMaritalStatus || !selectedAge || !selectedGender || !selectedIncomeRange || !selectedDependents) {
      // Handle case where not all inputs are selected
      setErrorMessage('Please fill in all the fields');
      return;
    }

    // Clear error message if all inputs are selected
    setErrorMessage('');

    const maritalStatus = selectedMaritalStatus.value;
    const age = selectedAge.value;
    const gender = selectedGender.value;
    const incomeRange = selectedIncomeRange.value;
    const dependents = selectedDependents.value;

    // Initialize income change and tax change values
    let incomeChangeValueBJP = 0;
    let taxChangeValueBJP = 0;
    let incomeChangeValueCongress = 0;
    let taxChangeValueCongress = 0;

    // BJP Policies
    // Estimate income change based on income range
    if (incomeRange === '5,00,000') {
      incomeChangeValueBJP = 1;
    } else if (incomeRange === '5,00,001') {
      incomeChangeValueBJP = 4;
    } else if (incomeRange === '10,00,001') {
      incomeChangeValueBJP = 5;
    } else if (incomeRange === '15,00,001') {
      incomeChangeValueBJP = 6;
    }

    // Adjust income change based on marital status
    if (maritalStatus === 'single') {
      incomeChangeValueBJP -= 1;
    } else if (maritalStatus === 'married') {
      incomeChangeValueBJP += 1;
    }

    // Adjust income change based on age
    if (age === 'under_18' || age === 'over_60') {
      incomeChangeValueBJP -= 1;
    } else if (age === '18_to_30') {
      incomeChangeValueBJP += 2;
    } else if (age === '31_to_45') {
      incomeChangeValueBJP += 2;
    } else if (age === '46_to_60') {
      incomeChangeValueBJP += 1;
    }

    // Adjust income change based on gender
    if (gender === 'female') {
      incomeChangeValueBJP -= 1;
    } else if (gender === 'male') {
      incomeChangeValueBJP += 1;
    } else if (gender === 'other') {
      incomeChangeValueBJP += 2;
    }

    // Adjust income change based on number of dependents
    if (dependents === '1') {
      incomeChangeValueBJP -= 1;
    } else if (dependents === '2') {
      incomeChangeValueBJP -= 2;
    } else if (dependents === '3_or_more') {
      incomeChangeValueBJP -= 3;
    } else if (dependents === 'none') {
      incomeChangeValueBJP += 2;
    }

    // Estimate tax change based on income change
    if (incomeChangeValueBJP >= 7) {
      taxChangeValueBJP = -2;
    } else if (incomeChangeValueBJP >= 3) {
      taxChangeValueBJP = -1;
    } else if (incomeChangeValueBJP <= -3) {
      taxChangeValueBJP = -1;
    }

    // Congress Policies
    // Estimate income change based on income range
    if (incomeRange === '5,00,000') {
      incomeChangeValueCongress = 3;
    } else if (incomeRange === '5,00,001') {
      incomeChangeValueCongress = 6;
    } else if (incomeRange === '10,00,001') {
      incomeChangeValueCongress = 4;
    } else if (incomeRange === '15,00,001') {
      incomeChangeValueCongress = 4;
    }

    // Adjust income change based on marital status
    if (maritalStatus === 'single') {
      incomeChangeValueCongress -= 1;
    } else if (maritalStatus === 'married') {
      incomeChangeValueCongress += 1;
    }

    // Adjust income change based on age
    if (age === 'under_18' || age === 'over_60') {
      incomeChangeValueCongress -= 1;
    } else if (age === '18_to_30') {
      incomeChangeValueCongress += 1;
    } else if (age === '31_to_45') {
      incomeChangeValueCongress += 3;
    } else if (age === '46_to_60') {
      incomeChangeValueCongress += 1;
    }

    // Adjust income change based on gender
    if (gender === 'female') {
      incomeChangeValueCongress -= 1;
    } else if (gender === 'male') {
      incomeChangeValueCongress += 1;
    } else if (gender === 'other') {
      incomeChangeValueCongress += 3;
    }

    // Adjust income change based on number of dependents
    if (dependents === '1') {
      incomeChangeValueCongress -= 1;
    } else if (dependents === '2') {
      incomeChangeValueCongress -= 2;
    } else if (dependents === '3_or_more') {
      incomeChangeValueCongress -= 2;
    } else if (dependents === 'none') {
      incomeChangeValueCongress += 2;
    }

    // Estimate tax change based on income change
    if (incomeChangeValueCongress >= 9) {
      taxChangeValueCongress = -2;
    } else if (incomeChangeValueCongress >= 5) {
      taxChangeValueCongress = -1;
    } else if (incomeChangeValueCongress <= -3) {
      taxChangeValueCongress = -1;
    }

    // Update state with the calculated values
    setIncomeChangeBJP(incomeChangeValueBJP === 0 ? '\u00B10%' : (incomeChangeValueBJP > 0 ? `+${incomeChangeValueBJP}%` : `${incomeChangeValueBJP}%`));
    setTaxChangeBJP(taxChangeValueBJP === 0 ? '\u00B10%' : (taxChangeValueBJP > 0 ? `+${taxChangeValueBJP}%` : `${taxChangeValueBJP}%`));

    setIncomeChangeCongress(incomeChangeValueCongress === 0 ? '\u00B10%' : (incomeChangeValueCongress > 0 ? `+${incomeChangeValueCongress}%` : `${incomeChangeValueCongress}%`));
    setTaxChangeCongress(taxChangeValueCongress === 0 ? '\u00B10%' : (taxChangeValueCongress > 0 ? `+${taxChangeValueCongress}%` : `${taxChangeValueCongress}%`));

    window.gtag('event', 'calculate_button_click', {
      event_category: 'engagement',
      event_label: 'Calculate Button Clicked'
    });

    const resultsSection = document.getElementById('results-section');
    resultsSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className="app-container">
      <div className="logo-name">
        <a href="https://www.truevote.in" rel="noopener noreferrer">
          <img src={logo} className="logo" alt="logo" />
        </a>
      </div>
      <div className="container">
        <div className="input-section">
          <h1 className="title">Enter Your Information</h1>
          <p className="description">Discover how Lok Sabha 2024 election manifesto policies of <a href="https://www.bjp.org/bjp-manifesto-2024" target="_blank" rel="noopener noreferrer">BJP</a> and <a href="https://manifesto.inc.in" target="_blank" rel="noopener noreferrer">Congress</a> affect you financially.</p>
          <div className="input-row">
            <div className="input-group" id="marital-input-group">
              <label className="label" htmlFor="marital-status">Marital Status</label>
              <div className="segmented-controls">
                <input className="radio-input" type="radio" id="single" name="marital_status" value="single" required />
                <label className="segmented-control" htmlFor="single">Single</label>
                <input className="radio-input" type="radio" id="married" name="marital_status" value="married" required />
                <label className="segmented-control" htmlFor="married">Married</label>
                <input className="radio-input" type="radio" id="divorced" name="marital_status" value="divorced" required />
                <label className="segmented-control" htmlFor="divorced">Divorced</label>
                <input className="radio-input" type="radio" id="widowed" name="marital_status" value="widowed" required />
                <label className="segmented-control" htmlFor="widowed">Widowed</label>
              </div>
            </div>
          </div>
          <div className="input-row">
            <div className="input-group">
              <label className="label" htmlFor="age">Age</label>
              <select className="select" id="age" name="age" required>
                <option value="" hidden>Select</option>
                <option value="under_18">Under 18</option>
                <option value="18_to_30">19 - 30</option>
                <option value="31_to_45">31 - 45</option>
                <option value="46_to_60">46 - 60</option>
                <option value="over_60">Over 60</option>
              </select>
            </div>
            <div className="input-group">
              <label className="label" htmlFor="gender">Gender</label>
              <div className="segmented-controls">
                <input className="radio-input" type="radio" id="male" name="gender" value="male" required />
                <label className="segmented-control" htmlFor="male">Male</label>
                <input className="radio-input" type="radio" id="female" name="gender" value="female" required />
                <label className="segmented-control" htmlFor="female">Female</label>
                <input className="radio-input" type="radio" id="other" name="gender" value="other" required />
                <label className="segmented-control" htmlFor="other">Other</label>
              </div>
            </div>
          </div>
          <div className="input-row">
            <div className="input-group">
              <label className="label" htmlFor="income_range">Income Range</label>
              <select className="select" id="income_range" name="income_range" required>
                <option value="" hidden>Select</option>
                <option value="5,00,000">Under &#x20B9;5,00,00</option>
                <option value="5,00,001">&#x20B9;5,00,001 - &#x20B9;10,00,000</option>
                <option value="10,00,001">&#x20B9;10,00,001 - &#x20B9;15,00,000</option>
                <option value="15,00,001">Over &#x20B9;15,00,000</option>
              </select>
            </div>
            <div className="input-group">
              <label className="label" htmlFor="dependents">No. of Dependents</label>
              <div className="segmented-controls">
                <input className="radio-input" type="radio" id="zero" name="dependents" value="0" required />
                <label className="segmented-control" htmlFor="zero">0</label>
                <input className="radio-input" type="radio" id="one" name="dependents" value="1" required />
                <label className="segmented-control" htmlFor="one">1</label>
                <input className="radio-input" type="radio" id="two" name="dependents" value="2" required />
                <label className="segmented-control" htmlFor="two">2</label>
                <input className="radio-input" type="radio" id="three_or_more" name="dependents" value="3_or_more" required />
                <label className="segmented-control" htmlFor="three_or_more">3+</label>
              </div>
            </div>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button className="calculate-button" onClick={calculateButton}>Calculate</button>
          <p className="secured-info"><span className="material-symbols-rounded">lock</span> Your information is secure and anonymous</p>
          <div className="share-buttons">
            <div className="share-text">
              <p>Share it!</p>
            </div>
            <TwitterShareButton url={shareUrl} title={titleWithTags}>
              <XIcon size={32} round className="share-icon" />
            </TwitterShareButton>
            <WhatsappShareButton url={shareUrl} title={title}>
              <WhatsappIcon size={32} round className="share-icon" />
            </WhatsappShareButton>
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={32} round className="share-icon" />
            </FacebookShareButton>
            <LinkedinShareButton url={shareUrl} title={titleWithTags}>
              <LinkedinIcon size={32} round className="share-icon" />
            </LinkedinShareButton>
            <RedditShareButton url={shareUrl} title={title}>
              <RedditIcon size={32} round className="share-icon" />
            </RedditShareButton>
          </div>
        </div>
        <div id="results-section" className="output-section">
          <div className="party-column">
            <div className="party">
              <img src={modiImage} alt="Narendra Modi" className="avatar" />
              <p>BJP</p>
            </div>
            <div className="change">
              <p>Income Change</p>
              <p className={`dynamic-numbers ${incomeChangeBJP > 0 ? 'positive' : incomeChangeBJP < 0 ? 'negative' : ''}`}>{incomeChangeBJP >= 0 ? `+${incomeChangeBJP}` : `${incomeChangeBJP}`}</p>
            </div>
            <div className="change">
              <p>Tax Change</p>
              <p className={`dynamic-numbers ${taxChangeBJP > 0 ? 'positive' : taxChangeBJP < 0 ? 'negative' : ''}`}>{taxChangeBJP >= 0 ? `+${taxChangeBJP}` : `${taxChangeBJP}`}</p>
            </div>
          </div>
          <div className="party-column">
            <div className="party">
              <img src={rahulImage} alt="Rahul Gandhi" className="avatar" />
              <p>Congress</p>
            </div>
            <div className="change">
              <p>Income Change</p>
              <p className={`dynamic-numbers ${incomeChangeCongress > 0 ? 'positive' : incomeChangeCongress < 0 ? 'negative' : ''}`}>{incomeChangeCongress >= 0 ? `+${incomeChangeCongress}` : `${incomeChangeCongress}`}</p>
            </div>
            <div className="change">
              <p>Tax Change</p>
              <p className={`dynamic-numbers ${taxChangeCongress > 0 ? 'positive' : taxChangeCongress < 0 ? 'negative' : ''}`}>{taxChangeCongress >= 0 ? `+${taxChangeCongress}` : `${taxChangeCongress}`}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="credit-disclaimer-container">
          <div class="credit">
            <a href="https://jaibhaarath.org" target="_blank" rel="noopener noreferrer" class="credit-text"> Created by Jai Bhaarath <span class="arrow">&#x2197;</span></a>
          </div>
          <div class="disclaimer">
            <p>The information provided is for educational purposes only and is not intended as financial, tax, or legal advice. Results may vary and are not guaranteed.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
